import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  AppProvider,
  ActionList,
  Card,
  TextField,
  TextContainer,
  ContextualSaveBar,
  FormLayout,
  Modal,
  Frame,
  Layout,
  Loading,
  Navigation,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Toast,
  TopBar,
} from "@shopify/polaris";
import {
  ProfileMinor,
  LogOutMinor,
  ImagesMajor,
  CategoriesMajor,
  SettingsMajor,
  QuestionMarkMajor,
  ListMajor,
  JobsMajor,
  CustomersMajor,
  ReportsMajor,
  AnalyticsMajor,
} from "@shopify/polaris-icons";
import Cookies from "js-cookie";
import whiteLogo from "../Assets/Images/logo-white.png";
import axios from "../Assets/Lib/axios";
import history from "../Assets/Lib/history";

function NavigationLayout(props) {
  const defaultState = useRef({
    emailFieldValue: "dharma@jadedpixel.com",
    nameFieldValue: "Jaded Pixel",
  });
  const skipToContentRef = useRef(null);

  const [toastActive, setToastActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [nameFieldValue, setNameFieldValue] = useState(
    defaultState.current.nameFieldValue
  );
  const [emailFieldValue, setEmailFieldValue] = useState(
    defaultState.current.emailFieldValue
  );
  const [storeName, setStoreName] = useState(
    defaultState.current.nameFieldValue
  );
  const [supportSubject, setSupportSubject] = useState("");
  const [supportMessage, setSupportMessage] = useState("");

  const handleSubjectChange = useCallback(
    (value) => setSupportSubject(value),
    []
  );
  const handleMessageChange = useCallback(
    (value) => setSupportMessage(value),
    []
  );
  const handleDiscard = useCallback(() => {
    setEmailFieldValue(defaultState.current.emailFieldValue);
    setNameFieldValue(defaultState.current.nameFieldValue);
    setIsDirty(false);
  }, []);
  const handleSave = useCallback(() => {
    defaultState.current.nameFieldValue = nameFieldValue;
    defaultState.current.emailFieldValue = emailFieldValue;

    setIsDirty(false);
    setToastActive(true);
    setStoreName(defaultState.current.nameFieldValue);
  }, [emailFieldValue, nameFieldValue]);
  const handleNameFieldChange = useCallback((value) => {
    setNameFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleEmailFieldChange = useCallback((value) => {
    setEmailFieldValue(value);
    value && setIsDirty(true);
  }, []);
  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue("");
  }, []);
  const handleSearchFieldChange = useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);
  const toggleToastActive = useCallback(
    () => setToastActive((toastActive) => !toastActive),
    []
  );
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );
  const toggleIsLoading = useCallback(
    () => setIsLoading((isLoading) => !isLoading),
    []
  );
  const toggleModalActive = useCallback(
    () => setModalActive((modalActive) => !modalActive),
    []
  );

  const toastMarkup = toastActive ? (
    <Toast onDismiss={toggleToastActive} content="Changes saved" />
  ) : null;

  const userMenuActions = [
    {
      items: [
        {
          content: "Your profile",
          icon: ProfileMinor,
          url: "/profile",
        },
      ],
    },
    {
      items: [
        {
          content: "Log out",
          icon: LogOutMinor,
          onAction: () => {
            axios
              .post("/admin/v1/auth/logout")
              .then((res) => {
                history.push("");
              })
              .catch((err) => console.log(err));
            Cookies.set("dte-accesstoken", null);
          },
        },
      ],
    },
  ];

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        onAction: handleSave,
      }}
      discardAction={{
        onAction: handleDiscard,
      }}
    />
  ) : null;

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={Cookies.get("dte-name") && Cookies.get("dte-name")}
      initials={
        Cookies.get("dte-name") &&
        Cookies.get("dte-name").split(" ")[0].charAt(0) +
          " " +
          Cookies.get("dte-name").split(" ")[1].charAt(0)
      }
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const searchResultsMarkup = (
    <ActionList
      items={[
        { content: "Shopify help center" },
        { content: "Community forums" },
      ]}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Search"
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      // searchResultsVisible={searchActive}
      // searchField={searchFieldMarkup}
      // searchResults={searchResultsMarkup}
      // onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        items={[
          // {
          //   url: "/admin/dashboard",
          //   label: "Dashboard",
          //   icon: AnalyticsMajor,
          //   selected: window.location.href.indexOf("/admin/dashboard") > -1,
          // },
          // {
          //   url: "/admin/groups",
          //   label: "Groups",
          //   icon: CategoriesMajor,
          //   selected: window.location.href.indexOf("/admin/groups") > -1,
          // },
          {
            url: "/admin/surveys",
            label: "Surveys",
            icon: CategoriesMajor,
            selected: window.location.href.indexOf("/admin/surveys") > -1,
          },
          // {
          //   url: "/admin/questions",
          //   label: "Questions",
          //   icon: QuestionMarkMajor,
          //   selected: window.location.href.indexOf("/admin/questions") > -1,
          // },
          // {
          //   url: "/admin/players",
          //   label: "Players",
          //   icon: CustomersMajor,
          //   selected: window.location.href.indexOf("/admin/players") > -1,
          // },
          // {
          //   url: "/admin/avatars",
          //   label: "Avatars",
          //   icon: ImagesMajor,
          //   selected: window.location.href.indexOf("/admin/avatars") > -1,
          // },
          // {
          //   url: "/admin/reports",
          //   label: "Reports",
          //   icon: ReportsMajor,
          //   selected: window.location.href.indexOf("/admin/reports") > -1,
          // },
        ]}
      ></Navigation.Section>
      <Navigation.Section
        separator
        items={[
          {
            icon: SettingsMajor,
            disabled: Cookies.get("role") !== "super-admin",
            label: "Settings",
            url: "/admin/settings",
            selected:
              window.location.href.indexOf("/admin/settings") > -1 ||
              (window.location.href.indexOf("/admin/users-management") > -1 &&
                true),
          },
        ]}
      />
    </Navigation>
  );
  const loadingMarkup = isLoading ? <Loading /> : null;

  const skipToContentTarget = (
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );

  const actualPageMarkup = (
    <Page title="Account">
      <Layout>
        {skipToContentTarget}
        <Layout.AnnotatedSection
          title="Account details"
          description="Jaded Pixel will use this as your account information."
        >
          <Card sectioned>
            <FormLayout>
              <TextField
                label="Full name"
                value={nameFieldValue}
                onChange={handleNameFieldChange}
              />
              <TextField
                type="email"
                label="Email"
                value={emailFieldValue}
                onChange={handleEmailFieldChange}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  const modalMarkup = (
    <Modal
      open={modalActive}
      onClose={toggleModalActive}
      title="Contact support"
      primaryAction={{
        content: "Send",
        onAction: toggleModalActive,
      }}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label="Subject"
            value={supportSubject}
            onChange={handleSubjectChange}
          />
          <TextField
            label="Message"
            value={supportMessage}
            onChange={handleMessageChange}
            multiline
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const theme = {
    colors: {
      topBar: {
        background: "#2c2c2d",
      },
    },
    // logo: {
    //   width: 124,
    //   topBarSource: whiteLogo,
    //   url: "/admin/dashboard",
    // },
  };

  return (
    <div style={{ height: "500px" }}>
      <AppProvider
        theme={theme}
        i18n={{
          Polaris: {
            Avatar: {
              label: "Avatar",
              labelWithInitials: "Avatar with initials {initials}",
            },
            ContextualSaveBar: {
              save: "Save",
              discard: "Discard",
            },
            TextField: {
              characterCount: "{count} characters",
            },
            TopBar: {
              toggleMenuLabel: "Toggle menu",

              SearchField: {
                clearButtonLabel: "Clear",
                search: "Search",
              },
            },
            Modal: {
              iFrameTitle: "body markup",
            },
            Frame: {
              skipToContent: "Skip to content",
              Navigation: {
                closeMobileNavigationLabel: "Close navigation",
              },
            },
          },
        }}
      >
        <Frame
          topBar={topBarMarkup}
          navigation={navigationMarkup}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}
          skipToContentTarget={skipToContentRef.current}
        >
          {props.children}
        </Frame>
      </AppProvider>
    </div>
  );
}
export default React.memo(NavigationLayout);
