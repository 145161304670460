import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  Form,
  Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import TagsInput from "react-tagsinput";
import { useParams } from "react-router-dom";
import { Switch } from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Editor } from "@tinymce/tinymce-react";
import { HexColorPicker } from "react-colorful";

const AddEdit = (props) => {
  const { id } = useParams();
  const [item, setItem] = useState({
    type: "",
    title: "",
    description: "",
    expiryDate: "",
    startDate: "",
    primaryColor: "",
    secondaryColor: "",
    selectedAnswerColor: "",
    image: "",
    metaTitle: "",
    metaKeywords: [],
    isPoints: false,
    points: "",
    thankyouText: "",
    imageGame: "",
    imageThankyou: "",
  });
  const handleChangeType = (newValue) => {
    setItem({ ...item, type: newValue });
    setTypeError("");
  };
  const [typeError, setTypeError] = useState("");
  const handleChangeTitle = (newValue) => {
    setItem({ ...item, title: newValue });
    setTitleError("");
  };
  const [titleError, setTitleError] = useState("");
  const handleChangeExpiryDate = (newValue) => {
    setItem({ ...item, expiryDate: newValue });
    setExpiryDateError("");
  };
  const [expiryDateError, setExpiryDateError] = useState("");
  const handleChangeStartDate = (newValue) => {
    setItem({ ...item, startDate: newValue });
    setStartDateError("");
  };
  const [startDateError, setStartDateError] = useState("");
  const [isSaving, setIsSaving] = useState(
    props?.type !== "edit" ? false : true
  );
  const handleChangeDescription = (newValue) => {
    setItem({ ...item, description: newValue });
  };
  const handleChangeThankyouText = (newValue) => {
    setItem({ ...item, thankyouText: newValue });
  };
  const handleChangePrimaryColor = (newValue) => {
    setItem({ ...item, primaryColor: newValue });
  };
  const handleChangeSecondaryColor = (newValue) => {
    setItem({ ...item, secondaryColor: newValue });
  };
  const handleChangeSelectedAnswerColor = (newValue) => {
    setItem({ ...item, selectedAnswerColor: newValue });
  };
  const handleChangeMetaTitle = (newValue) => {
    setItem({ ...item, metaTitle: newValue });
  };
  const handleChangeKeywordsMeta = (neValue) => {
    setItem({ ...item, metaKeywords: neValue });
  };
  const handleChangePoints = (neValue) => {
    setItem({ ...item, points: neValue });
  };
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [imagePreview, setImagePreview] = useState("");
  const [files, setfiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setfiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedfiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setItem({
      ...item,
      image:
        validImageTypes.indexOf(files[files.length - 1].type) > 0
          ? window.URL.createObjectURL(files[files.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
  };

  const [filesGame, setfilesGame] = useState([]);
  const handleDropZoneGame = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setfilesGame((filesGame) => [...filesGame, ...acceptedFiles]),
    []
  );
  const fileUploadGame = !filesGame.length && <DropZone.FileUpload />;
  let uploadedfilesGame = filesGame.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesGame[filesGame.length - 1].name}
        source={
          validImageTypes.indexOf(filesGame[filesGame.length - 1].type) > 0
            ? window.URL.createObjectURL(filesGame[filesGame.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesGame[filesGame.length - 1].name}{" "}
        <Caption>{filesGame[filesGame.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageGame = (filesGame) => {
    setItem({
      ...item,
      imageGame:
        validImageTypes.indexOf(filesGame[filesGame.length - 1].type) > 0
          ? window.URL.createObjectURL(filesGame[filesGame.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
  };

  const [filesThankyou, setfilesThankyou] = useState([]);
  const handleDropZoneThankyou = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setfilesThankyou((filesThankyou) => [...filesThankyou, ...acceptedFiles]),
    []
  );
  const fileUploadThankyou = !filesThankyou.length && <DropZone.FileUpload />;
  let uploadedfilesThankyou = filesThankyou.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesThankyou[filesThankyou.length - 1].name}
        source={
          validImageTypes.indexOf(
            filesThankyou[filesThankyou.length - 1].type
          ) > 0
            ? window.URL.createObjectURL(
                filesThankyou[filesThankyou.length - 1]
              )
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesThankyou[filesThankyou.length - 1].name}{" "}
        <Caption>{filesThankyou[filesThankyou.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageThankyou = (filesThankyou) => {
    setItem({
      ...item,
      imageThankyou:
        validImageTypes.indexOf(filesThankyou[filesThankyou.length - 1].type) >
        0
          ? window.URL.createObjectURL(filesThankyou[filesThankyou.length - 1])
          : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304",
    });
  };
  const optionsTypes = [
    { label: "Quiz", value: "quiz" },
    { label: "Survey", value: "survey" },
  ];
  useEffect(() => {
    props.type === "edit" &&
      axios
        .get(`admin/v1/assessments/${id}`)
        .then((result) => {
          console.log(result?.data?.data?.points);
          setIsSaving(false);
          setItem({
            title: result?.data?.data?.title ? result.data.data.title : "",
            type: result?.data?.data?.type ? result.data.data.type : "",
            expiryDate: result?.data?.data?.expiry_date
              ? result.data.data.expiry_date
              : "",
            startDate: result?.data?.data?.start_date
              ? result.data.data.start_date
              : "",
            description: result?.data?.data?.description
              ? result.data.data.description
              : "",
            primaryColor: result?.data?.data?.primary_color
              ? result.data.data.primary_color
              : "",

            secondaryColor: result?.data?.data?.secondary_color
              ? result.data.data.secondary_color
              : "",
            selectedAnswerColor: result?.data?.data?.selected_answer_color
              ? result.data.data.selected_answer_color
              : "",
            thankyouText: result?.data?.data?.thanks_text
              ? result.data.data.thanks_text
              : "",
            image: result?.data?.data?.intro_image
              ? result.data.data.intro_image
              : "",
            imageGame: result?.data?.data?.game_image
              ? result.data.data.game_image
              : "",
            imageThankyou: result?.data?.data?.thanks_image
              ? result.data.data.thanks_image
              : "",
            metaTitle: result?.data?.data?.meta_title
              ? result.data.data.meta_title
              : "",
            isPoints: result?.data?.data?.is_points ? true : false,
            points:
              result?.data?.data?.points !== null &&
              result?.data?.data?.is_points
                ? String(result.data.data.points)
                : "",
            metaKeywords:
              result?.data?.data?.meta_keywords &&
              result?.data?.data?.meta_keywords?.length
                ? result.data.data.meta_keywords
                : [],
          });
        })
        .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title={`${props.type === "add" ? "Add" : "Edit"} Survey/Quiz`}
      breadcrumbs={[{ url: "/admin/surveys" }]}
    >
      {isSaving ? <Loading /> : null}
      <Card>
        <Card.Section>
          <FormLayout>
            <Select
              label="Type"
              options={optionsTypes}
              onChange={handleChangeType}
              value={item.type}
              error={typeError}
              placeholder="Please select"
            />
            <FormLayout.Group>
              <FormLayout>
                <TextField
                  value={item.startDate}
                  onChange={handleChangeStartDate}
                  align="left"
                  error={startDateError}
                  type="date"
                  label="Start Date"
                />
              </FormLayout>
              <FormLayout>
                <TextField
                  value={item.expiryDate}
                  onChange={handleChangeExpiryDate}
                  align="left"
                  error={expiryDateError}
                  type="date"
                  label="Expiry Date"
                />
              </FormLayout>
            </FormLayout.Group>

            <FormLayout.Group>
              <FormLayout>
                <TextStyle>Title</TextStyle>
                <Editor
                  apiKey="8tj30x1inciao3p1nhocjjbe5khxgi67o00cwklmq9wc32ti"
                  value={item.title}
                  init={{
                    branding: false,
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                  }}
                  onEditorChange={handleChangeTitle}
                />

                <InlineError message={titleError} />
              </FormLayout>
              <FormLayout>
                <TextStyle>Description</TextStyle>
                <Editor
                  apiKey="8tj30x1inciao3p1nhocjjbe5khxgi67o00cwklmq9wc32ti"
                  value={item.description}
                  init={{
                    branding: false,
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                  }}
                  onEditorChange={handleChangeDescription}
                />

                <InlineError message={titleError} />
              </FormLayout>
            </FormLayout.Group>

            <TextStyle>Thank You Page Text</TextStyle>
            <Editor
              apiKey="8tj30x1inciao3p1nhocjjbe5khxgi67o00cwklmq9wc32ti"
              value={item.thankyouText}
              init={{
                branding: false,
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={handleChangeThankyouText}
            />

            <FormLayout.Group condensed>
              <FormLayout>
                <TextField
                  value={item.primaryColor}
                  onChange={handleChangePrimaryColor}
                  label="Buttons Background Color"
                />
                <HexColorPicker
                  color={item.primaryColor}
                  onChange={handleChangePrimaryColor}
                />
              </FormLayout>
              <FormLayout>
                <TextField
                  value={item.secondaryColor}
                  onChange={handleChangeSecondaryColor}
                  label="Buttons Text Color"
                />
                <HexColorPicker
                  color={item.secondaryColor}
                  onChange={handleChangeSecondaryColor}
                />
              </FormLayout>
              {/* <FormLayout>
                <TextField
                  value={item.selectedAnswerColor}
                  onChange={handleChangeSelectedAnswerColor}
                  label="Selected Answer Color"
                />
              </FormLayout> */}
            </FormLayout.Group>
            <FormLayout.Group condensed>
              <FormLayout>
                <TextStyle variation="normal">
                  Introduction Background Image
                </TextStyle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ float: "left" }}>
                    <Thumbnail
                      size="large"
                      source={item.image ? item.image : ""}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginLeft: "10px",
                    }}
                  >
                    <DropZone
                      onDrop={handleDropZone}
                      onDropAccepted={sendImage}
                    >
                      {uploadedfiles}
                      {fileUpload}
                    </DropZone>
                  </div>
                </div>
              </FormLayout>
              <FormLayout>
                <TextStyle variation="normal">Game Background Image</TextStyle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ float: "left" }}>
                    <Thumbnail
                      size="large"
                      source={item.imageGame ? item.imageGame : ""}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginLeft: "10px",
                    }}
                  >
                    <DropZone
                      onDrop={handleDropZoneGame}
                      onDropAccepted={sendImageGame}
                    >
                      {uploadedfilesGame}
                      {fileUploadGame}
                    </DropZone>
                  </div>
                </div>
              </FormLayout>
            </FormLayout.Group>
            <TextStyle variation="normal">
              Thank you Page Background Image
            </TextStyle>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div style={{ float: "left" }}>
                <Thumbnail
                  size="large"
                  source={item.imageThankyou ? item.imageThankyou : ""}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <DropZone
                  onDrop={handleDropZoneThankyou}
                  onDropAccepted={sendImageThankyou}
                >
                  {uploadedfilesThankyou}
                  {fileUploadThankyou}
                </DropZone>
              </div>
            </div>
          </FormLayout>
        </Card.Section>
        {/* <Card.Section title="META INFO">
          <FormLayout>
            <TextField
              value={item.metaTitle}
              onChange={handleChangeMetaTitle}
              label="Title"
            />
            <TextStyle>Keywords</TextStyle>
            <TagsInput
              value={item.metaKeywords}
              onChange={handleChangeKeywordsMeta}
              // inputProps={{
              //   placeholder:
              //     "Enter keyword here and use the Enter key to add multiple keywords",
              // }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={item.isPoints}
                  onChange={() => {
                    setItem({ ...item, isPoints: !item.isPoints });
                  }}
                />
              }
              label="Points"
              labelPlacement="start"
            />
            {item.isPoints && (
              <TextField
                value={item.points}
                onChange={handleChangePoints}
                label="Points"
                type="number"
              />
            )}
          </FormLayout>
        </Card.Section> */}
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={
          props.type === "edit" && [
            {
              content: "Delete",
              destructive: true,
              loading: isSaving && true,
              onClick: () => {
                setIsSaving(true);
                axios
                  .delete(`admin/v1/assessments/${id}`)
                  .then((result) => {
                    history.push(`/admin/surveys`);
                  })
                  .catch((err) => console.log(err));
              },
            },
          ]
        }
      />
    </Page>
  );

  function handleSave() {
    if (!item.title || !item.type) {
      !item.title && setTitleError("This field is required");
      !item.type && setTypeError("This field is required");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      item.title && form_data.append("title", item.title);
      form_data.append("type", item.type);
      form_data.append("description", item.description);
      form_data.append("primary_color", item.primaryColor);
      form_data.append("secondary_color", item.secondaryColor);
      form_data.append("selected_answer_color", item.selectedAnswerColor);
      form_data.append("expiry_date", item.expiryDate);
      form_data.append("start_date", item.startDate);
      files.length && form_data.append("intro_image", files[files.length - 1]);
      filesGame.length &&
        form_data.append("game_image", filesGame[filesGame.length - 1]);
      form_data.append("thanks_text", item.thankyouText);
      filesThankyou.length &&
        form_data.append(
          "thanks_image",
          filesThankyou[filesThankyou.length - 1]
        );
      item.metaTitle && form_data.append("meta_title", item.metaTitle);
      item.metaKeywords.length &&
        form_data.append("meta_keywords", item.metaKeywords);
      form_data.append("is_points", item.isPoints ? 1 : 0);
      props.type === "edit" && form_data.append("_method", "PATCH");
      form_data.append("points", item.points);
      props.type === "add"
        ? axios
            .post(`admin/v1/assessments`, form_data)
            .then((result) => {
              history.push("/admin/surveys");
            })
            .catch((err) => console.log(err))
        : axios
            .post(`admin/v1/assessments/${id}`, form_data)
            .then((result) => {
              history.push("/admin/surveys");
            })
            .catch((err) => console.log(err));
    }
  }
};
export default AddEdit;
