import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Lib/history";
import List from "./List";
import NavigationLayout from "../../Components/NavigationLayout";
import AddEdit from "./AddEdit";
import MainEdit from "./MainEdit";
import Cookies from "js-cookie";

const MainSurveys = () => {
  return (
    <NavigationLayout>
      <Router history={history}>
        <Switch>
          <Route exact path="/admin/surveys" component={List} />
          {Cookies.get("role") !== "back-office" && (
            <Route
              path="/admin/surveys/new"
              component={() => <AddEdit type={"add"} />}
            />
          )}

          {Cookies.get("role") !== "back-office" && (
            <Route path="/admin/surveys/:id" component={MainEdit} />
          )}
        </Switch>
      </Router>
    </NavigationLayout>
  );
};
export default MainSurveys;
