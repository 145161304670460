import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  Form,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import TagsInput from "react-tagsinput";
import { useParams } from "react-router-dom";

const AddEdit = (props) => {
  const { id, questionId, answerId } = useParams();
  const [item, setItem] = useState({
    answer: "",
    isCorrect: false,
    question: "",
  });
  const [optionsQuestions, setOptionsQuestions] = useState([
    { label: "None", value: "none" },
  ]);
  const handleSelectChangeQuestion = (newValue) => {
    setItem({ ...item, question: newValue });
  };
  const handleChangeAnswer = (newValue) => {
    setItem({ ...item, answer: newValue });
    setAnswerError("");
  };
  const handleChangeCorrect = (newValue) => {
    setItem({ ...item, isCorrect: newValue });
  };
  const [answerError, setAnswerError] = useState("");

  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    props.type === "edit" &&
      axios
        .get(`admin/v1/answers/${answerId}`)
        .then((result) => {
          console.log(result?.data?.data?.points);
          setIsSaving(false);
          setItem({
            answer: result?.data?.data?.text ? result.data.data.text : "",
            isCorrect: result?.data?.data?.is_correct ? true : false,
            question:
              result?.data?.data?.skip_to_question_id !== null
                ? String(result?.data?.data?.skip_to_question_id)
                : "",
          });
        })
        .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    axios
      .get(`admin/v1/questions?filter[assessment_id]=${id}`)
      .then((result) => {
        setOptionsQuestions((currentObjects) =>
          currentObjects.concat([
            ...result.data.data.data.map((item, index) => {
              return {
                label: item.title,
                value: String(item.id),
              };
            }),
          ])
        );
        // setOptionsQuestions(
        //   result.data.data.data.map((item, index) => {
        //     return {
        //       label: item.title,
        //       value: item.id,
        //     };
        //   })
        // );
      })
      .catch((err) => console.log(""));
  }, []);

  return (
    <Page
      title={`${props.type === "add" ? "Add" : "Edit"} Answer`}
      breadcrumbs={[
        { url: `/admin/surveys/${id}/questions/${questionId}/answers` },
      ]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <TextField
            value={item.answer}
            onChange={handleChangeAnswer}
            error={answerError}
            label="Answer"
          />
          <Select
            label="Skip to question"
            options={optionsQuestions}
            onChange={handleSelectChangeQuestion}
            value={item.question}
            placeholder="Please select"
          />
          <Checkbox
            label="Is Correct"
            checked={item.isCorrect}
            onChange={handleChangeCorrect}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={
          props.type === "edit" && [
            {
              content: "Delete",
              destructive: true,
              loading: isSaving && true,
              onClick: () => {
                setIsSaving(true);
                axios
                  .delete(`admin/v1/answers/${answerId}`)
                  .then((result) => {
                    history.push(
                      `/admin/surveys/${id}/questions/${questionId}/answers`
                    );
                  })
                  .catch((err) => console.log(err));
              },
            },
          ]
        }
      />
    </Page>
  );

  function handleSave() {
    if (!item.answer) {
      !item.answer && setAnswerError("This field is required");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      item.answer && form_data.append("text", item.answer);
      form_data.append("question_id", questionId);
      form_data.append("is_correct", item.isCorrect ? 1 : 0);
      item.answer && form_data.append("text", item.answer);
      form_data.append(
        "skip_to_question_id",
        item.question === "none" ? "" : item.question
      );
      props.type === "edit" && form_data.append("_method", "PATCH");

      props.type === "add"
        ? axios
            .post(`admin/v1/answers`, form_data)
            .then((result) => {
              setIsSaving(false);
              setItem({
                ...item,
                question: "",
                answer: "",
                isCorrect: false,
              });
              // history.push(
              //   `/admin/surveys/${id}/questions/${questionId}/answers`
              // );
            })
            .catch((err) => console.log(err))
        : axios
            .post(`admin/v1/answers/${answerId}`, form_data)
            .then((result) => {
              history.push(
                `/admin/surveys/${id}/questions/${questionId}/answers`
              );
            })
            .catch((err) => console.log(err));
    }
  }
};
export default AddEdit;
