import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  Form,
  Select,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import TagsInput from "react-tagsinput";
import { useParams } from "react-router-dom";

const AddEdit = (props) => {
  const { id, questionId } = useParams();
  const [item, setItem] = useState({
    question: "",
    type: "",
  });
  const handleChangeQuestion = (newValue) => {
    setItem({ ...item, question: newValue });
    setQuestionError("");
  };
  const [questionError, setQuestionError] = useState("");
  const handleChangeType = (newValue) => {
    setItem({ ...item, type: newValue });
    setTypeError("");
  };
  const [typeError, setTypeError] = useState("");
  const optionsTypes = [
    { label: "Multiple Choice", value: "multiple_choice" },
    { label: "Input Text", value: "text_field " },
  ];
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    props.type === "edit" &&
      axios
        .get(`admin/v1/questions/${questionId}`)
        .then((result) => {
          console.log(result?.data?.data?.points);
          setIsSaving(false);
          setItem({
            question: result?.data?.data?.title ? result.data.data.title : "",
            type: result?.data?.data?.type ? result.data.data.type : "",
          });
        })
        .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title={`${props.type === "add" ? "Add" : "Edit"} Question`}
      breadcrumbs={[{ url: `/admin/surveys/${id}/questions` }]}
    >
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <Select
            label="Type"
            options={optionsTypes}
            onChange={handleChangeType}
            value={item.type}
            error={typeError}
            placeholder="Please select"
          />
          <TextField
            value={item.question}
            onChange={handleChangeQuestion}
            error={questionError}
            label="Question"
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={
          props.type === "edit" && [
            {
              content: "Delete",
              destructive: true,
              loading: isSaving && true,
              onClick: () => {
                setIsSaving(true);
                axios
                  .delete(`admin/v1/questions/${questionId}`)
                  .then((result) => {
                    history.push(`/admin/surveys/${id}/questions`);
                  })
                  .catch((err) => console.log(err));
              },
            },
          ]
        }
      />
    </Page>
  );

  function handleSave() {
    if (!item.question || item.type === "") {
      !item.question && setQuestionError("This field is required");
      item.type === "" && setTypeError("This field is required");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      item.question && form_data.append("title", item.question);
      item.type !== "" && form_data.append("type", item.type);
      form_data.append("assessment_id", id);
      props.type === "edit" && form_data.append("_method", "PATCH");
      props.type === "add"
        ? axios
            .post(`admin/v1/questions`, form_data)
            .then((result) => {
              setItem({ ...item, question: "", type: "" });
              setIsSaving(false);
              //history.push(`/admin/surveys/${id}/questions`);
            })
            .catch((err) => console.log(err))
        : axios
            .post(`admin/v1/questions/${questionId}`, form_data)
            .then((result) => {
              history.push(`/admin/surveys/${id}/questions`);
            })
            .catch((err) => console.log(err));
    }
  }
};
export default AddEdit;
