import React, { useCallback, useState, useEffect } from "react";
import {
  FormLayout,
  TextField,
  Card,
  Page,
  TextStyle,
  Select,
  PageActions,
  Loading,
  Button,
  Toast,
  Modal,
  TextContainer,
} from "@shopify/polaris";
import NavigationLayout from "../Components/NavigationLayout";
import axios from "../Assets/Lib/axios";
import history from "../Assets/Lib/history";
import { useRef } from "react";

export default function Reports() {
  const reference = useRef("");
  const [isSaving, setIsSaving] = useState(false);
  const [active, setActive] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastContent} onDismiss={toggleActive} />
  ) : null;
  const [isB1Loading, setIsB1Loading] = useState(false);
  const [isB2Loading, setIsB2Loading] = useState(false);
  const [isB3Loading, setIsB3Loading] = useState(false);
  const [isB4Loading, setIsB4Loading] = useState(false);
  const [isB5Loading, setIsB5Loading] = useState(false);
  const [isB6Loading, setIsB6Loading] = useState(false);
  const [isB7Loading, setIsB7Loading] = useState(false);
  const [isB8Loading, setIsB8Loading] = useState(false);
  const [activePopup, setActivePopup] = useState(false);
  const [isYesLoading, setIsYesLoading] = useState(false);
  const handleChangePopup = useCallback(
    () => setActivePopup(!activePopup),
    [activePopup]
  );
  const activator = <Button onClick={handleChangePopup}>Open</Button>;
  const handleButtonClick = (param) => {
    // param === "questions" && setIsB1Loading(true);
    // param === "ranking" && setIsB2Loading(true);
    // param === "points" && setIsB3Loading(true);
    // param === "timing" && setIsB4Loading(true);
    param !== "timing"
      ? window
          .open(
            `${process.env.REACT_APP_BASE_URL}admin/report/${param}?token=c3433c5e711e32c542b5cc5g5a54db12`,
            "_blank"
          )
          .focus()
      : window
          .open(
            `${process.env.REACT_APP_BASE_URL}admin/report/${param}${
              selected !== "all" ? `/${selected}` : ""
            }?token=c3433c5e711e32c542b5cc5g5a54db12`,
            "_blank"
          )
          .focus();
    axios
      .get(`/admin/v1/report/${param}`)
      .then((res) => {
        // param === "questions" && setIsB1Loading(false);
        // param === "ranking" && setIsB2Loading(false);
        // param === "points" && setIsB3Loading(false);
        // param === "timing" && setIsB4Loading(false);
      })
      .catch((err) => console.log(""));
  };
  const [selected, setSelected] = useState("all");

  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const [options, setOptions] = useState([{ label: "All", value: "all" }]);

  useEffect(() => {
    axios
      .get(`admin/v1/leads?per_page=10000`)
      .then((result) => {
        setIsSaving(false);

        console.log(result.data.data);

        // setOptions((oldArray) => [
        //   ...new Set([
        //     ...oldArray,
        //     result.data.data.data.map((item, index) => {
        //       return {
        //         label: item.name,
        //         value: String(item.id),
        //       };
        //     }),
        //   ]),
        // ]);
        setOptions((currentObjects) =>
          currentObjects.concat([
            ...result.data.data.data.map((item, index) => {
              return {
                label: item.name,
                value: String(item.id),
              };
            }),
          ])
        );

        // result?.data?.data?.data &&
        //   setOptions((oldArray) => [
        //     ...oldArray,
        //     result.data.data.data.map((item, index) => {
        //       return {
        //         label: item.name,
        //         value: String(item.id),
        //       };
        //     }),
        //   ]);

        // result?.data?.data?.data &&
        //   setOptions(
        //     result.data.data.data.map((item, index) => {
        //       return {
        //         label: item.name,
        //         value: String(item.id),
        //       };
        //     })
        //   );
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <NavigationLayout>
      <Page title="Reports">
        {isSaving ? <Loading /> : null}
        <Card sectioned>
          <FormLayout.Group>
            <Button
              primary
              fullWidth
              loading={isB1Loading}
              onClick={() => handleButtonClick("questions")}
            >
              Questions Report
            </Button>
            <Button
              fullWidth
              primary
              loading={isB2Loading}
              onClick={() => handleButtonClick("ranking")}
            >
              Ranking Report
            </Button>
          </FormLayout.Group>
          <FormLayout.Group>
            <Button
              primary
              fullWidth
              loading={isB3Loading}
              onClick={() => handleButtonClick("points")}
            >
              Points Report
            </Button>
          </FormLayout.Group>
          <FormLayout.Group>
            <FormLayout>
              <Select
                label="Please select a player"
                options={options}
                onChange={handleSelectChange}
                value={selected}
                helpText="Please select a player to download the timing report"
                placeholder="Please select a player"
              />

              <Button
                fullWidth
                primary
                loading={isB4Loading}
                onClick={() => handleButtonClick("timing")}
              >
                Timing Report
              </Button>
            </FormLayout>
          </FormLayout.Group>
        </Card>
        {/* <Modal
          // activator={activator}
          open={activePopup}
          onClose={handleChangePopup}
          title={`Are you sure you want to reset ${
            reference.current.split("_")[1]
          }?`}
          primaryAction={{
            content: "Yes",
            onAction: handleYes,
            loading: isYesLoading,
          }}
          secondaryActions={[
            {
              content: "No",
              onAction: handleChangePopup,
            },
          ]}
        ></Modal> */}
        {toastMarkup}
      </Page>
    </NavigationLayout>
  );
}
