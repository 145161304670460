import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../../Assets/Lib/history";
import List from "./List";
import NavigationLayout from "../../../Components/NavigationLayout";
import AddEdit from "./AddEdit";
import MainAnswers from "../Answers/Main";
import Statistics from "../Statistics";

const MainEdit = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/admin/surveys/:id/questions/:questionId"
          component={() => <AddEdit type={"edit"} />}
        />
        <Route
          path="/admin/surveys/:id/questions/:questionId/answers"
          component={MainAnswers}
        />
        <Route
          path="/admin/surveys/:id/questions/:questionId/statistics"
          component={Statistics}
        />
      </Switch>
    </Router>
  );
};
export default MainEdit;
