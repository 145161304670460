import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../../Assets/Lib/history";
import List from "./List";
import NavigationLayout from "../../../Components/NavigationLayout";
import AddEdit from "./AddEdit";
import MainEdit from "../MainEdit";
import Cookies from "js-cookie";

const MainAnswers = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/admin/surveys/:id/questions/:questionId/answers"
          component={List}
        />
        {Cookies.get("role") !== "back-office" && (
          <Route
            path="/admin/surveys/:id/questions/:questionId/answers/new"
            component={() => <AddEdit type={"add"} />}
          />
        )}
        {Cookies.get("role") !== "back-office" && (
          <Route
            path="/admin/surveys/:id/questions/:questionId/answers/:answerId"
            component={() => <AddEdit type={"edit"} />}
          />
        )}
      </Switch>
    </Router>
  );
};
export default MainAnswers;
