import React, { useCallback, useState, useEffect } from "react";
import {
  Thumbnail,
  Caption,
  DropZone,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Loading,
  Card,
  Modal,
  PageActions,
  Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import Cookies from "js-cookie";
import history from "../../Assets/Lib/history";

const Add = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordConfirmationValue, setPasswordConfirmationValue] =
    useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const handleChangeFirstName = useCallback((newValue) => {
    setFirstNameValue(newValue);
    setFirstNameError("");
  }, []);
  const handleChangeLastName = useCallback((newValue) => {
    setLastNameValue(newValue);
    setLastNameError("");
  }, []);
  const handleChangeEmail = useCallback((newValue) => {
    setEmailValue(newValue);
    setEmailError("");
  }, []);
  const handleChangePassword = useCallback((newValue) => {
    setPasswordValue(newValue);
    setPasswordError("");
  }, []);
  const handleChangePasswordConfirmation = useCallback((newValue) => {
    setPasswordConfirmationValue(newValue);
    setPasswordError("");
    setConfirmPasswordError("");
  }, []);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const handleSelectChangeRole = (newValue) => {
    setSelectedRole(newValue);
    setRoleError("");
  };
  const optionsRoles = [
    { label: "Super Admin", value: "super-admin" },
    { label: "Back Office", value: "back-office" },
    { label: "Analytical", value: "analytic" },
  ];
  const [roleError, setRoleError] = useState("");
  return (
    <Page
      title="Add User"
      breadcrumbs={[{ content: "Admin Users", url: "/admin/users-management" }]}
      narrowWidth
    >
      {isSaving ? <Loading /> : null}

      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              value={firstNameValue}
              onChange={handleChangeFirstName}
              label="First Name"
              error={firstNameError}
            />
            <TextField
              value={lastNameValue}
              onChange={handleChangeLastName}
              label="Last Name"
              error={lastNameError}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={emailValue}
              onChange={handleChangeEmail}
              label="Email"
              error={emailError}
            />
          </FormLayout.Group>
          <Select
            label="Role"
            options={optionsRoles}
            onChange={handleSelectChangeRole}
            value={selectedRole}
            placeholder="Please select"
            error={roleError}
          />
          <FormLayout.Group>
            <TextField
              value={passwordValue}
              onChange={handleChangePassword}
              label="Password"
              error={passwordError}
              helpText="Password must be at least 8 characters"
            />
            <TextField
              value={passwordConfirmationValue}
              onChange={handleChangePasswordConfirmation}
              label="Confirm Password"
              error={confirmPasswordError}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      selectedRole === "" ||
      !firstNameValue ||
      !lastNameValue ||
      !emailValue ||
      (passwordValue.length < 8 && passwordValue) ||
      (emailValue &&
        !emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
      passwordConfirmationValue !== passwordValue ||
      !passwordValue ||
      !passwordConfirmationValue
    ) {
      selectedRole === "" && setRoleError("Please select a role");
      !firstNameValue && setFirstNameError("This field is required");
      !lastNameValue && setLastNameError("This field is required");
      !emailValue && setEmailError("This field is required");
      emailValue &&
        !emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) &&
        setEmailError("Please enter a valid email");
      !passwordValue && setPasswordError("This field is required");
      !passwordConfirmationValue &&
        setConfirmPasswordError("This field is required");
      passwordValue &&
        passwordValue.length < 8 &&
        setPasswordError("Password must be at least 8 characters");
      passwordConfirmationValue != passwordValue &&
        setPasswordError("Password are not matching");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      firstNameValue && form_data.append("first_name", firstNameValue);
      lastNameValue && form_data.append("last_name", lastNameValue);
      emailValue && form_data.append("email", emailValue);
      passwordValue && form_data.append("password", passwordValue);
      passwordConfirmationValue &&
        form_data.append("confirm-password", passwordConfirmationValue);
      selectedRole !== "" && form_data.append("role", selectedRole);

      axios
        .post("/admin/v1/users", form_data)
        .then((res) => {
          history.push("/admin/users-management");
        })
        .catch((error) => {
          // Error
          console.log(error);
          setIsSaving(false);
          if (error.response) {
            setPasswordError(
              <ul>
                {error.response.data.errors.password.map((element, index) => {
                  return (
                    <div key={index}>
                      <h2>{element}</h2>
                    </div>
                  );
                })}
              </ul>
            );
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
  }
};
export default Add;
