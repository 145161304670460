import React, { useCallback, useState } from "react";
import "@shopify/polaris/dist/styles.css";
import { Switch, Route, Router } from "react-router-dom";
import history from "./Assets/Lib/history";
import { AppProvider, Toast } from "@shopify/polaris";
import "./Assets/Styles/globals.css";
import "./Assets/Styles/react-tagsinput.css";
import Login from "./Pages/Authentication/Login";
import ForgotPassword from "./Pages/Authentication/ForgotPassword";
import Profile from "./Pages/Profile";
import ResetPassword from "./Pages/Authentication/ResetPassword";
import Settings from "./Pages/Settings";
import Reports from "./Pages/Reports";
import Dashboard from "./Pages/Dashboard";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import AdminUsers from "./Pages/AdminUsers/Main";
import MainSurveys from "./Pages/Surveys/Main";

function App() {
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }

  return (
    <GoogleReCaptchaProvider
      ptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
    >
      <AppProvider
        theme={{
          colors: {
            surface: "#111213",
            onSurface: "#111213",
            interactive: "#2e72d2",
            secondary: "#111213",
            primary: "#294b80",
            critical: "#d82c0d",
            warning: "#ffc453",
            highlight: "#5bcdda",
            success: "#008060",
            decorative: "#ffc96b",
          },
        }}
      >
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/profile" component={Profile} />
            <Route path="/admin/settings" component={Settings} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/password/reset" component={ResetPassword} />
            <Route path="/admin/surveys" component={MainSurveys} />
            <Route path="/admin/reports" component={Reports} />
            <Route path="/admin/dashboard" component={Dashboard} />
            <Route path="/admin/users-management" component={AdminUsers} />
          </Switch>
        </Router>
      </AppProvider>
    </GoogleReCaptchaProvider>
  );
}
export default App;
