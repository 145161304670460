import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import history from "../../Assets/Lib/history";
import axios from "../../Assets/Lib/axios";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Checkbox,
  Form,
  Select,
} from "@shopify/polaris";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
const Statistics = () => {
  const { id, questionId } = useParams();
  const [result, setResult] = useState();
  useEffect(() => {
    axios
      .get(`admin/v1/stats/question/${questionId}`)
      .then((result) => {
        console.log(result?.data?.data);
        setResult(result?.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Statistics"
      breadcrumbs={[{ url: `/admin/surveys/${id}/questions` }]}
    >
      {result && (
        <Card>
          <Card.Section title={`Question: ` + result?.title}>
            <FormLayout>
              {result?.answer_stats?.map((element, index) => {
                return (
                  <div key={index}>
                    <b>
                      Answer {index + 1} ({element.percentage}%)
                    </b>
                    <h2>{element.answer}</h2>
                  </div>
                );
              })}
            </FormLayout>
          </Card.Section>
          <Card.Section>
            <BarChart
              width={500}
              height={300}
              data={result?.answer_stats?.map((element, index) => {
                return {
                  name: element?.answer,
                  percentage: element?.percentage,
                };
              })}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={20}
            >
              <XAxis
                dataKey="name"
                scale="point"
                padding={{ left: 10, right: 10 }}
              />
              <YAxis />
              <Tooltip />
              {/* <Legend /> */}
              <CartesianGrid strokeDasharray="3 3" />
              <Bar
                dataKey="percentage"
                fill="#8884d8"
                background={{ fill: "#eee" }}
              />
            </BarChart>
          </Card.Section>
        </Card>
      )}
    </Page>
  );
};

export default Statistics;
