import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Thumbnail,
  Icon,
  Badge,
} from "@shopify/polaris";
import axios from "../../../Assets/Lib/axios";
import history from "../../../Assets/Lib/history";
import emptyListImage from "../../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { AnalyticsMajor } from "@shopify/polaris-icons";
const List = () => {
  const { id } = useParams();
  const format = "YYYY-MM-DD HH:mm:ss";
  const perPage = 200;
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleEdit = (e) => {
    history.push(`/admin/surveys/${id}/questions/${e.currentTarget.id}`);
  };
  const handleAnswers = (e) => {
    history.push(
      `/admin/surveys/${id}/questions/${e.currentTarget.id}/answers`
    );
  };
  const handleStatistics = (e) => {
    history.push(
      `/admin/surveys/${id}/questions/${e.currentTarget.id}/statistics`
    );
  };
  useEffect(() => {
    getData();
  }, [page, sortState, availability, queryValue]);

  const getData = () => {
    axios
      .get(
        `admin/v1/questions?filter[assessment_id]=${id}&per_page=${perPage}&page=${page}`
      )
      .then((result) => {
        setIsLoading(false);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        result?.data?.data?.data &&
          setItems(
            result.data.data.data.map((item) => [
              item?.title && item.title,
              item?.type && (
                <Badge
                  status={item?.type === "multiple_choice" ? "info" : "default"}
                >
                  {item?.type === "multiple_choice"
                    ? "Multiple Choice"
                    : "Input Text"}
                </Badge>
              ),
              item?.stats ? item.stats : "0",

              <ButtonGroup>
                {item?.type === "multiple_choice" ? (
                  <Button
                    onClick={handleStatistics}
                    id={item.id}
                    icon={AnalyticsMajor}
                  >
                    Statistics
                  </Button>
                ) : null}
                {item?.type === "multiple_choice" ? (
                  <Button onClick={handleAnswers} id={item.id} primary>
                    Answers
                  </Button>
                ) : null}
                <Button
                  onClick={handleEdit}
                  id={item.id}
                  disabled={Cookies.get("role") === "back-office"}
                >
                  Edit
                </Button>
              </ButtonGroup>,
            ])
          );
      })
      .catch((err) => setIsLoading(false));
  };
  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Name", value: "name" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );
  const [isExporting, setIsExporting] = useState(false);
  const handleExport = () => {
    window.open(
      `https://pmi-survey.creo-dev.com/admin/report/questions?token=c3433c5e711e32c542b5cc5g5a54db12&assessment_id=${id}`,
      "_blank"
    );
    // setIsExporting(true);
    // const bodyObj = {
    //   token: "c3433c5e711e32c542b5cc5g5a54db12",
    //   assessment_id: id,
    // };
    // axios
    //   .get(
    //     `admin/report/questions?token=c3433c5e711e32c542b5cc5g5a54db12&assessment_id=${id}`,
    //     bodyObj
    //   )
    //   .then((result) => {
    //     setIsExporting(false);
    //     //setToastContent("Please check your email to find the exported sheet");
    //     //toggleActive();
    //   })
    //   .catch((err) => console.log(err));
  };
  return (
    <Page
      fullWidth
      title="Questions"
      primaryAction={{
        content: "Add Question",
        url: `/admin/surveys/${id}/questions/new`,
        disabled: Cookies.get("role") === "back-office",
      }}
      breadcrumbs={[{ url: "/admin/surveys" }]}
      // secondaryActions={[
      //   {
      //     content: "Export",
      //     icon: ExportMinor,
      //     onAction: handleExport,
      //     loading: isExporting,
      //   },
      // ]}
    >
      <Card>
        {loadingMarkup}
        <Card.Section>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
          />
        </Card.Section>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">Question</TextStyle>,
            <TextStyle variation="strong">Title</TextStyle>,
            <TextStyle variation="strong">Users (%)</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default List;
