import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Select,
  Layout,
  DisplayText,
  Icon,
  ProgressBar,
  Subheading,
  Badge,
} from "@shopify/polaris";
import axios from "../Assets/Lib/axios";
import history from "../Assets/Lib/history";
import { useParams } from "react-router-dom";
import NavigationLayout from "../Components/NavigationLayout";
import Chart from "react-apexcharts";
import {
  ChecklistMajor,
  MobileAcceptMajor,
  CustomersMajor,
  GrammarMajor,
  MobileCancelMajor,
  CalendarTickMajor,
  ListMajor,
} from "@shopify/polaris-icons";
function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const options = {
    chart: {
      id: "apexchart-example",
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
    tooltip: {
      enabled: true,
      offsetY: -35,
    },
    fill: {
      colors: ["#FF8A65"],
    },
  };
  const series = [
    {
      name: "series-1",
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
    },
  ];
  const [selectedSeason, setSelectedSeason] = useState("");
  const handleSelectChangeSeason = useCallback(
    (value) => setSelectedSeason(value),
    []
  );
  const [optionsSeasons, setOptionsSeasons] = useState([]);
  const [result, setResult] = useState("");
  useEffect(() => {
    if (!options?.length) {
      axios
        .get(`admin/v1/seasons`)
        .then((result) => {
          // setIsSaving(false);
          console.log(result.data.data.data);
          result?.data?.data?.data &&
            setOptionsSeasons(
              result.data.data.data.map((item, index) => {
                return {
                  label: item.name,
                  value: String(item.id),
                };
              })
            );
          axios
            .get(`admin/v1/dashboard/${result.data.data.data[0].id}`)
            .then((result) => {
              console.log(result.data.data);
              setIsLoading(false);
              setResult(result.data.data);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(`admin/v1/dashboard/${selectedSeason}`)
        .then((result) => {
          console.log(result.data.data);
          setResult(result.data.data);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <NavigationLayout>
      <Page fullWidth>
        {result && (
          <FormLayout>
            <div style={{ marginTop: "30px" }}>
              <FormLayout>
                <TextStyle variation="strong">Select Season</TextStyle>
                <Select
                  options={optionsSeasons}
                  onChange={handleSelectChangeSeason}
                  value={selectedSeason}
                />
              </FormLayout>
            </div>
            <Layout>
              <Layout.Section oneThird>
                <Card
                  sectioned
                  title={
                    <Stack>
                      <Icon source={CustomersMajor} />
                      <Subheading>Total Players</Subheading>
                    </Stack>
                  }
                >
                  <FormLayout>
                    <DisplayText>
                      {result?.players_data?.total_players}
                    </DisplayText>
                  </FormLayout>
                </Card>
              </Layout.Section>
              <Layout.Section oneThird>
                <Card
                  sectioned
                  title={
                    <Stack>
                      <Icon source={CustomersMajor} />
                      <Subheading>New Players Today</Subheading>
                    </Stack>
                  }
                >
                  <FormLayout>
                    <DisplayText>
                      {" "}
                      {result?.players_data?.new_players_today}
                    </DisplayText>
                  </FormLayout>
                </Card>
              </Layout.Section>
              <Layout.Section oneThird>
                <Card
                  sectioned
                  title={
                    <Stack>
                      <Icon source={CustomersMajor} />
                      <Subheading>New Players This Week</Subheading>
                    </Stack>
                  }
                >
                  <FormLayout>
                    <DisplayText>
                      {result?.players_data?.new_players_this_week}
                    </DisplayText>
                  </FormLayout>
                </Card>
              </Layout.Section>
              <Layout.Section oneThird>
                <Card
                  sectioned
                  title={
                    <Stack>
                      <Icon source={CustomersMajor} />
                      <Subheading>New Players This Month</Subheading>
                    </Stack>
                  }
                >
                  <FormLayout>
                    <DisplayText>
                      {result?.players_data?.new_players_this_month}
                    </DisplayText>
                  </FormLayout>
                </Card>
              </Layout.Section>
            </Layout>

            {/* <Layout>
            <Layout.Section oneThird>
              <Card
                sectioned
                title={
                  <Stack>
                    <Icon source={ChecklistMajor} />
                    <Subheading>Completed Questions (Level 1)</Subheading>
                  </Stack>
                }
              >
                <FormLayout>
                  <DisplayText>3</DisplayText>
                </FormLayout>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card
                sectioned
                title={
                  <Stack>
                    <Icon source={ChecklistMajor} />
                    <Subheading>Completed Questions (Level 2)</Subheading>
                  </Stack>
                }
              >
                <FormLayout>
                  <DisplayText>3</DisplayText>
                </FormLayout>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card
                sectioned
                title={
                  <Stack>
                    <Icon source={ChecklistMajor} />
                    <Subheading>Completed Questions (Level 3)</Subheading>
                  </Stack>
                }
              >
                <FormLayout>
                  <DisplayText>3</DisplayText>
                </FormLayout>
              </Card>
            </Layout.Section>
          </Layout> */}
            {/* <Card
            sectioned
            title={
              <Stack>
                <Icon source={GrammarMajor} />
                <Subheading>Level 1 Correct Answer Rate (34%)</Subheading>
              </Stack>
            }
          >
            <ProgressBar progress={34} size="small" />
          </Card> */}

            <Layout>
              <Layout.Section>
                <Card
                  sectioned
                  title={
                    <Stack>
                      <Icon source={CustomersMajor} />
                      <Subheading>New Players / Day</Subheading>
                    </Stack>
                  }
                >
                  <Chart
                    options={{
                      chart: {
                        id: "apexchart-example",
                      },
                      xaxis: {
                        // categories: [
                        //   1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
                        // ],
                        categories:
                          result?.new_players_chart &&
                          result?.new_players_chart.map((item, idx) => {
                            return item.date;
                          }),
                        labels: {
                          rotate: -90,
                        },
                      },
                      tooltip: {
                        enabled: true,
                        offsetY: -35,
                      },
                      fill: {
                        colors: ["#FF8A65"],
                      },
                      dataLabels: {
                        enabled: false,
                        // formatter: function (val) {
                        //   return Number(val).toLocaleString() + "€";
                        // },
                        offsetY: -20,
                        style: {
                          fontSize: "12px",
                          colors: ["#304758"],
                        },
                      },
                    }}
                    // series={[
                    //   {
                    //     name: "series-1",
                    //     data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
                    //   },
                    // ]}
                    series={[
                      {
                        name: "",
                        data:
                          result?.new_players_chart &&
                          result?.new_players_chart.map((item, idx) => {
                            return item.value;
                          }),
                      },
                    ]}
                    type="bar"
                    width="100%"
                    height={320}
                  />
                </Card>
              </Layout.Section>
              <Layout.Section>
                <Card
                  sectioned
                  title={
                    <Stack>
                      <Icon source={GrammarMajor} />
                      <Subheading>Completed Levels / Day</Subheading>
                    </Stack>
                  }
                >
                  <Chart
                    options={{
                      chart: {
                        id: "apexchart-example",
                      },
                      xaxis: {
                        // categories: [
                        //   1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
                        // ],
                        categories:
                          result?.completed_levels_chart &&
                          result?.completed_levels_chart.map((item, idx) => {
                            return item.date;
                          }),
                        labels: {
                          rotate: -90,
                        },
                      },
                      tooltip: {
                        enabled: true,
                        offsetY: -35,
                      },
                      fill: {
                        colors: ["#FF8A65"],
                      },

                      dataLabels: {
                        enabled: false,
                        // formatter: function (val) {
                        //   return Number(val).toLocaleString() + "€";
                        // },
                        offsetY: -20,
                        style: {
                          fontSize: "12px",
                          colors: ["#304758"],
                        },
                      },
                    }}
                    // series={[
                    //   {
                    //     name: "series-1",
                    //     data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
                    //   },
                    // ]}
                    series={[
                      {
                        name: "",
                        data:
                          result?.completed_levels_chart &&
                          result?.completed_levels_chart.map((item, idx) => {
                            return item.value;
                          }),
                      },
                    ]}
                    type="bar"
                    width="100%"
                    height={320}
                  />
                </Card>
              </Layout.Section>

              <Layout.Section>
                <Card
                  sectioned
                  title={
                    <Stack>
                      <Icon source={GrammarMajor} />
                      <Subheading>Answered Questions / Day</Subheading>
                    </Stack>
                  }
                >
                  <Chart
                    options={{
                      chart: {
                        id: "apexchart-example",
                      },
                      xaxis: {
                        // categories: [
                        //   1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
                        // ],
                        categories:
                          result?.answered_questions_chart &&
                          result?.answered_questions_chart.map((item, idx) => {
                            return item.date;
                          }),
                        labels: {
                          rotate: -90,
                        },
                      },
                      tooltip: {
                        enabled: true,
                        offsetY: -35,
                      },
                      fill: {
                        colors: ["#FF8A65"],
                      },
                      dataLabels: {
                        enabled: false,
                        // formatter: function (val) {
                        //   return Number(val).toLocaleString() + "€";
                        // },
                        offsetY: -20,
                        style: {
                          fontSize: "12px",
                          colors: ["#304758"],
                        },
                      },
                    }}
                    // series={[
                    //   {
                    //     name: "series-1",
                    //     data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
                    //   },
                    // ]}
                    series={[
                      {
                        name: "",
                        data:
                          result?.answered_questions_chart &&
                          result?.answered_questions_chart.map((item, idx) => {
                            return item.value;
                          }),
                      },
                    ]}
                    type="bar"
                    width="100%"
                    height={320}
                  />
                </Card>
              </Layout.Section>
            </Layout>

            <Layout>
              <Layout.Section oneHalf>
                <Card
                  sectioned
                  title={
                    <Stack>
                      <Icon source={MobileAcceptMajor} />
                      <Subheading>
                        Top 5 Questions Answered Correctly
                      </Subheading>
                    </Stack>
                  }
                >
                  {result?.top_5_questions_answered_correctly && (
                    <FormLayout>
                      <div className="divRow">
                        <TextStyle>
                          {
                            result?.top_5_questions_answered_correctly[0]
                              .question
                          }
                        </TextStyle>
                        <div style={{ float: "right" }}>
                          <Badge status="info">
                            {
                              result?.top_5_questions_answered_correctly[0]
                                .occurences
                            }
                          </Badge>
                        </div>
                      </div>
                      <div className="divRow">
                        <TextStyle>
                          {
                            result?.top_5_questions_answered_correctly[1]
                              .question
                          }
                        </TextStyle>
                        <div style={{ float: "right" }}>
                          <Badge status="info">
                            {
                              result?.top_5_questions_answered_correctly[1]
                                .occurences
                            }
                          </Badge>
                        </div>
                      </div>
                      <div className="divRow">
                        <TextStyle>
                          {
                            result?.top_5_questions_answered_correctly[2]
                              .question
                          }
                        </TextStyle>
                        <div style={{ float: "right" }}>
                          <Badge status="info">
                            {
                              result?.top_5_questions_answered_correctly[2]
                                .occurences
                            }
                          </Badge>
                        </div>
                      </div>
                      <div className="divRow">
                        <TextStyle>
                          {
                            result?.top_5_questions_answered_correctly[3]
                              .question
                          }
                        </TextStyle>
                        <div style={{ float: "right" }}>
                          <Badge status="info">
                            {
                              result?.top_5_questions_answered_correctly[3]
                                .occurences
                            }
                          </Badge>
                        </div>
                      </div>
                      <div className="divRow">
                        <TextStyle>
                          {
                            result?.top_5_questions_answered_correctly[4]
                              .question
                          }
                        </TextStyle>
                        <div style={{ float: "right" }}>
                          <Badge status="info">
                            {
                              result?.top_5_questions_answered_correctly[4]
                                .occurences
                            }
                          </Badge>
                        </div>
                      </div>
                    </FormLayout>
                  )}
                </Card>
              </Layout.Section>
              <Layout.Section oneHalf>
                <Card
                  sectioned
                  title={
                    <Stack>
                      <Icon source={MobileCancelMajor} />
                      <Subheading>Top 5 Questions Answered Wrongly</Subheading>
                    </Stack>
                  }
                >
                  {result?.top_5_questions_answered_wrongly && (
                    <FormLayout>
                      <div className="divRow2">
                        <TextStyle>
                          {result?.top_5_questions_answered_wrongly[0].question}
                        </TextStyle>
                        <div style={{ float: "right" }}>
                          <Badge status="info">
                            {
                              result?.top_5_questions_answered_wrongly[0]
                                .occurences
                            }
                          </Badge>
                        </div>
                      </div>
                      <div className="divRow2">
                        <TextStyle>
                          {result?.top_5_questions_answered_wrongly[1].question}
                        </TextStyle>
                        <div style={{ float: "right" }}>
                          <Badge status="info">
                            {
                              result?.top_5_questions_answered_wrongly[1]
                                .occurences
                            }
                          </Badge>
                        </div>
                      </div>
                      <div className="divRow2">
                        <TextStyle>
                          {result?.top_5_questions_answered_wrongly[2].question}
                        </TextStyle>
                        <div style={{ float: "right" }}>
                          <Badge status="info">
                            {
                              result?.top_5_questions_answered_wrongly[2]
                                .occurences
                            }
                          </Badge>
                        </div>
                      </div>
                      <div className="divRow2">
                        <TextStyle>
                          {result?.top_5_questions_answered_wrongly[3].question}
                        </TextStyle>
                        <div style={{ float: "right" }}>
                          <Badge status="info">
                            {
                              result?.top_5_questions_answered_wrongly[3]
                                .occurences
                            }
                          </Badge>
                        </div>
                      </div>
                      <div className="divRow2">
                        <TextStyle>
                          {result?.top_5_questions_answered_wrongly[4].question}
                        </TextStyle>
                        <div style={{ float: "right" }}>
                          <Badge status="info">
                            {
                              result?.top_5_questions_answered_wrongly[4]
                                .occurences
                            }
                          </Badge>
                        </div>
                      </div>
                    </FormLayout>
                  )}
                </Card>
              </Layout.Section>
            </Layout>
            <Card>
              {result?.levels?.map((levelItem, id) => {
                return (
                  <div key={id}>
                    <Card.Section
                      title={
                        levelItem.level_name.charAt(0).toUpperCase() +
                        levelItem.level_name.slice(1)
                      }
                    >
                      <FormLayout>
                        <TextStyle>
                          <b>Completed Questions : </b>
                          {levelItem.completed_questions}
                        </TextStyle>
                        <TextStyle>
                          <b>
                            {`Correct Answer Rate (${Math.round(
                              levelItem.correct_answer_rate
                            )}%)`}{" "}
                          </b>
                        </TextStyle>
                        <ProgressBar
                          progress={levelItem.correct_answer_rate}
                          size="small"
                        />
                      </FormLayout>
                    </Card.Section>
                  </div>
                );
              })}
            </Card>
          </FormLayout>
        )}

        <Modal open={isLoading} loading={true}></Modal>
      </Page>
    </NavigationLayout>
  );
}

export default Dashboard;
