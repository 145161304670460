import React, { useCallback, useState, useEffect } from "react";
import {
  Thumbnail,
  Caption,
  DropZone,
  Stack,
  TextStyle,
  Page,
  AppProvider,
  FormLayout,
  TextField,
  Loading,
  Card,
  Modal,
  PageActions,
  Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import Cookies from "js-cookie";
import history from "../../Assets/Lib/history";
import { useParams } from "react-router-dom";

const Edit = () => {
  const { id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordConfirmationValue, setPasswordConfirmationValue] =
    useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const handleChangeFirstName = (newValue) => {
    setItem({ ...item, firstName: newValue });
    setFirstNameError("");
  };
  const handleChangeLastName = (newValue) => {
    setItem({ ...item, lastName: newValue });
    setLastNameError("");
  };
  const handleChangeEmail = (newValue) => {
    setItem({ ...item, email: newValue });
    setEmailError("");
  };
  const handleChangePassword = (newValue) => {
    setPasswordValue(newValue);
    setPasswordError("");
  };
  const handleChangePasswordConfirmation = (newValue) => {
    setPasswordConfirmationValue(newValue);
    setPasswordError("");
    setConfirmPasswordError("");
  };
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const handleSelectChangeRole = (newValue) => {
    setItem({ ...item, role: newValue });
    setRoleError("");
  };
  const optionsRoles = [
    { label: "Super Admin", value: "super-admin" },
    { label: "Back Office", value: "back-office" },
  ];
  const [roleError, setRoleError] = useState("");

  const [item, setItem] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });
  useEffect(() => {
    axios
      .get(`/admin/v1/users/${id}`)
      .then((result) => {
        console.log(result.data);
        setIsSaving(false);
        setItem({
          firstName: result?.data?.data?.first_name
            ? result.data.data.first_name
            : "",
          lastName: result?.data?.data?.last_name
            ? result.data.data.last_name
            : "",
          email: result?.data?.data?.email ? result.data.data.email : "",
          role:
            result?.data?.data?.roles && result?.data?.data?.roles[0] !== ""
              ? String(result.data.data.roles[0].name)
              : "",
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page
      title="Edit User"
      breadcrumbs={[{ content: "Admin Users", url: "/admin/users-management" }]}
      narrowWidth
    >
      {isSaving ? <Loading /> : null}

      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              value={item.firstName}
              onChange={handleChangeFirstName}
              label="First Name"
              error={firstNameError}
            />
            <TextField
              value={item.lastName}
              onChange={handleChangeLastName}
              label="Last Name"
              error={lastNameError}
            />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField
              value={item.email}
              onChange={handleChangeEmail}
              label="Email"
              error={emailError}
            />
          </FormLayout.Group>
          <Select
            label="Role"
            options={optionsRoles}
            onChange={handleSelectChangeRole}
            value={item.role}
            placeholder="Please select"
            error={roleError}
          />
          <FormLayout.Group>
            <TextField
              value={passwordValue}
              onChange={handleChangePassword}
              label="New Password"
              error={passwordError}
              helpText="Password must be at least 8 characters"
            />
            <TextField
              value={passwordConfirmationValue}
              onChange={handleChangePasswordConfirmation}
              label="Confirm Password"
              error={confirmPasswordError}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .delete(`/admin/v1/users/${id}`)
                .then((result) => {
                  history.push(`/admin/users-management`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (
      item.role === "" ||
      !item.firstName ||
      !item.lastName ||
      !item.email ||
      (passwordValue.length < 8 && passwordValue) ||
      (item.email && !item.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
    ) {
      item.role === "" && setRoleError("Please select a role");
      !item.firstName && setFirstNameError("This field is required");
      !item.lastName && setLastNameError("This field is required");
      !item.email && setEmailError("This field is required");
      item.email &&
        !item.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) &&
        setEmailError("Please enter a valid email");

      passwordValue &&
        passwordValue.length < 8 &&
        setPasswordError("Password must be at least 8 characters");
      passwordConfirmationValue != passwordValue &&
        setPasswordError("Password are not matching");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      item.firstName && form_data.append("first_name", item.firstName);
      item.lastName && form_data.append("last_name", item.lastName);
      item.email && form_data.append("email", item.email);
      passwordValue && form_data.append("password", passwordValue);
      passwordConfirmationValue &&
        form_data.append("confirm-password", passwordConfirmationValue);
      item.role !== "" && form_data.append("role", item.role);
      form_data.append("_method", "PATCH");
      axios
        .post(`/admin/v1/users/${id}`, form_data)
        .then((res) => {
          history.push("/admin/users-management");
        })
        .catch((err) => console.log(err));
    }
  }
};
export default Edit;
