import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../Assets/Lib/history";
import List from "./List";
import NavigationLayout from "../../Components/NavigationLayout";
import AddEdit from "./AddEdit";
import MainQuestions from "./Questions/Main";

const MainEdit = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/admin/surveys/:id"
          component={() => <AddEdit type={"edit"} />}
        />
        <Route path="/admin/surveys/:id/questions" component={MainQuestions} />
      </Switch>
    </Router>
  );
};
export default MainEdit;
